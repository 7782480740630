<template>
  <th-page-wrapper
    :title="$t('common.resource.stock_taking.singular')"
    color="var(--secondary-color)"
    align-title="center"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'stock-takings-list' })"
  >
    <div class="edit-button-holder">
      <el-button
        type="primary"
        :style="{ visibility: isEditable ? 'hidden' : 'visible' }"
        @click="() => (isEditable = true)"
      >
        {{ $t('common.interactions.buttons.edit') }}
      </el-button>
    </div>
    <div>
      <stock-takings-form ref="form" :editable="isEditable" />
    </div>
  </th-page-wrapper>
</template>

<script>
import StockTakingsForm from './components/form.vue'

export default {
  name: 'StockTakingsEdit',
  components: {
    StockTakingsForm
  },
  data: () => ({
    isEditable: false
  }),
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'inventory:stock_takings',
        returnedPath: { name: 'stock-takings-list' },
        isNew: this.isNew
      }
    }
  },
  mounted() {
    this.isEditable = this.isNew
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
  padding: 0px 20px;
  height: 100%;
}

.edit-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
}
</style>
