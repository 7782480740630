<template>
  <div class="form-wrapper mouseflow-hide">
    <div class="edit-form-wrapper">
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-col
          class="form-column"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="form-row">
            <el-form-item prop="name" :label="$t('common.headers.name.title')">
              <el-input v-if="isEditable" v-model="form.name" />
              <div v-else>
                {{ form.name }}
              </div>
            </el-form-item>
            <el-form-item
              prop="description"
              :label="$t('common.headers.description.title')"
            >
              <el-input v-if="isEditable" v-model="form.description" />
              <div v-else>
                {{ form.description }}
              </div>
            </el-form-item>
          </div>

          <div v-if="isEditable" class="add-processes-button-holder">
            <el-button
              v-permissions="{
                scopes: [
                  'inventory:stock_takings:create',
                  'inventory:stock_takings:update'
                ]
              }"
              type="primary"
              @click="() => (showAddProcessesDialog = !showAddProcessesDialog)"
            >
              {{ $t('pages.stock_takings.edit.add_processes_button') }}
            </el-button>
          </div>
          <el-form-item
            :label="$t('pages.stock_takings.all.headers.processes')"
          >
            <el-table
              v-loading="isFetchingProcesses"
              :data="processes"
              :empty-text="$t('components.th_datatable.no_data')"
            >
              <el-table-column
                prop="name"
                :label="$t('common.headers.name.title')"
              />
              <el-table-column
                prop="started_at"
                :label="$t('pages.processes.all.headers.started_at')"
                :formatter="startedAtFormatter"
              />
              <el-table-column
                prop="finished_at"
                :label="$t('pages.processes.all.headers.finished_at')"
                :formatter="finishedAtFormatter"
              />
              <el-table-column
                prop="location"
                :label="$t('common.headers.location.title')"
                :formatter="locationFormatter"
              />
              <el-table-column
                prop="assigned_staff"
                :label="$t('pages.processes.all.headers.assigned_staff')"
                :formatter="assignedStaffFormatter"
              />
              <el-table-column v-if="isEditable" fixed="right" align="center">
                <template #default="scope">
                  <el-button text @click="() => handleOpenProcess(scope.row)">
                    {{ $t('pages.stock_takings.edit.view_process') }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                v-if="isEditable"
                fixed="right"
                align="center"
                width="64"
              >
                <template #default="scope">
                  <el-button
                    icon="Delete"
                    class="el-button--text-icon"
                    @click="() => handleRemoveProcess(scope.row)"
                  />
                </template>
              </el-table-column>
              <template #empty>
                <div class="empty-table-holder">
                  <img
                    src="../../../../assets/illustrations/empty_stock_taking_processes_table.svg"
                  />
                  <div class="empty-table-text">
                    {{ $t('pages.stock_takings.edit.no_processes') }}
                  </div>
                </div>
              </template>
            </el-table>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
    <el-dialog
      v-model="showAddProcessesDialog"
      :title="$t('pages.stock_takings.edit.add_processes_title')"
    >
      <group-items-select
        v-model="processes"
        class="processes-group-select"
        resource="processes"
        :title="$t('pages.stock_takings.edit.processes_selection.title')"
        :normalise-keys="normaliseKeys"
        show-search-filter
        fuzzy-search
        :filters="filtersList"
        :resource-query="{ deleted: false, fields: ['id', 'name'] }"
        full-selected-object
      />
      <template #footer>
        <div>
          <el-button
            type="primary"
            @click="() => (showAddProcessesDialog = false)"
          >
            {{ $t('common.interactions.buttons.close') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import fullName from '@/utils/full-name'
import GroupItemsSelect from '@/components/group-items-select'

export default {
  name: 'StockTakingsForm',
  components: {
    GroupItemsSelect
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const form = {
      name: undefined,
      description: undefined,
      processes: []
    }
    return {
      loading: false,
      form,
      payload: {},
      fields: ['name', 'description', 'processes'],
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.stock_takings.edit.form.field_warnings.required'
            )
          },
          {
            min: 1,
            max: 128,
            message: this.$t(
              'pages.stock_takings.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ]
      },
      processes: [],
      locations: [],
      staff: [],
      showAddProcessesDialog: false,
      isFetchingProcesses: false
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    isEditable() {
      return this.isNew || this.editable
    },
    navigationAfterCreation() {
      return this.$store.getters['Config/getNavigationAfterCreation']
    },
    translations() {
      return {
        created: this.$t('common.processes.statuses.created.title'),
        started: this.$t('common.processes.statuses.started.title'),
        paused: this.$t('common.processes.statuses.paused.title'),
        cancelled: this.$t('common.processes.statuses.cancelled.title'),
        finished: this.$t('common.processes.statuses.finished.title')
      }
    },
    filtersList() {
      return [
        {
          name: 'status',
          type: 'multiselect',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.processes.all.headers.status'),
          options: [
            'created',
            'started',
            'paused',
            'cancelled',
            'finished'
          ].map((status) => ({
            label: this.translations[status],
            value: status
          }))
        },
        {
          name: 'location',
          type: 'remote-search-select',
          label: this.$t('common.headers.location.title'),
          resource: 'branches',
          filterable: true,
          doInitialFetch: true,
          computeName: (branch) => branch.name,
          modifyQuery: (q) => ({
            q,
            fields: ['name']
          }),
          appendToBody: true
        },
        {
          name: 'assigned_staff',
          type: 'remote-search-select',
          label: this.$t('pages.processes.all.headers.assigned_staff'),
          options: [],
          resource: 'staff',
          filterable: false,
          doInitialFetch: true,
          computeName: (staff) => this.$formatStaff(staff, ['fullName']),
          appendToBody: true
        },
        {
          name: 'started_at',
          type: 'daterange',
          label: this.$t('pages.processes.all.headers.started_at'),
          formatValue: (value) => this.$date.formatDateRange(value)
        },
        {
          name: 'finished_at',
          type: 'daterange',
          label: this.$t('pages.processes.all.headers.finished_at'),
          formatValue: (value) => this.$date.formatDateRange(value)
        }
      ]
    }
  },
  created() {
    this.$emitter.on('save-requested', this.submitForm)
  },
  async mounted() {
    if (!this.isNew) await this.fetch(this.$route.params.id)

    try {
      await Promise.all([
        this.fetchLocations(),
        this.fetchStaff(),
        this.fetchProcesses()
      ])
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  methods: {
    fullName,
    async fetch(id) {
      const errorMessage = this.$t(
        'pages.stock_takings.edit.form.errors.fetch.code_XXX.content'
      )
      try {
        this.loading = true
        const { data = {} } = await th.stockTakings().get(id)

        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    submitForm() {
      const warningMessage = this.$t(
        'pages.stock_takings.edit.form.warnings.invalid_inputs.contents'
      )
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: warningMessage
          })
        }

        this.loading = true
        const payload = this.form

        // convert this.processes to array of IDs and set that on form
        payload.processes = this.processes.map(({ id }) => id)

        if (this.isNew) return this.create(payload)
        this.alter(this.payload.id, payload)
      })
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate(cb)
    },
    async create(payload) {
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.stock_taking.singular')
      })

      try {
        const { data = {} } = await th.stockTakings().create(payload)

        this.loading = false
        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.stock_taking.singular')
            })
          })

          this.handleItem(data)

          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'stock-takings-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'stock-takings-list' })
          }
        }
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async alter(id, payload) {
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.stock_taking.singular')
      })
      try {
        const { data = {} } = await th.stockTakings().update(id, payload)

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.stock_taking.singular')
            })
          })

          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    handleItem(item) {
      this.payload = item
      this.form = pick(item, this.fields)
    },
    resetForm() {
      if (this.isNew) {
        this.$refs.form.resetFields()
      } else {
        this.handleItem(this.payload)
      }
    },
    fetchLocations: async function () {
      const { data } = await th
        .stocks()
        .getLocations({ query: { deleted: false } })
      this.locations = data || []
    },
    fetchStaff: async function () {
      const { staff = [] } = await this.$resourceFetch('staff')
      this.staff = staff
    },
    fetchProcesses: async function () {
      this.isFetchingProcesses = true
      try {
        const processesRaw = await Promise.all(
          this.form.processes.map((pId) =>
            th.processes().get(pId, {
              fields: [
                'id',
                'name',
                'started_at',
                'finished_at',
                'status',
                'location',
                'assigned_staff'
              ]
            })
          )
        )
        this.processes = processesRaw.map(({ data }) => data)
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.isFetchingProcesses = false
      }
    },
    locationFormatter(row) {
      const location = row.location

      if (!location) return '-'

      if (!this.locations) return location

      return (this.locations.find((l) => l.id === location) || {}).name
    },
    assignedStaffFormatter(row) {
      const assignedStaff = row.assigned_staff

      if (!assignedStaff) return '-'

      if (!this.staff) return assignedStaff

      const foundStaff = this.staff.find((l) => l.id === assignedStaff) || {}
      return foundStaff.displayname || fullName(foundStaff)
    },
    startedAtFormatter(row) {
      const startedAt = row.started_at
      if (!startedAt) return '-'
      return this.$date.formatDateTimeWithTimezone(startedAt)
    },
    finishedAtFormatter(row) {
      const finishedAt = row.finished_at
      if (!finishedAt) return '-'
      return this.$date.formatDateTimeWithTimezone(finishedAt)
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.id
      )
      if (confirm) this.deleteStockTaking()
    },
    async deleteStockTaking() {
      const resource = this.$t('common.resource.stock_taking.singular')
      const errorMessage = this.$t('common.forms.error.delete_fail.title', {
        resource
      })
      try {
        await th.stockTakings().delete(this.payload.id)

        this.resetForm()
        this.$router.push({ name: 'stock-takings-list' })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    handleOpenProcess(row) {
      const routeData = this.$router.resolve({
        name: 'processes-edit',
        params: { id: row.id }
      })
      window.open(routeData.href, '_blank')
    },
    async handleRemoveProcess(row) {
      const objectName = `${this.$t(
        'common.resource.process.singular'
      ).toLowerCase()} '${row.name || row.id}'`

      const confirmMessage = this.$t('common.remove_confirm.content', {
        objectName
      })

      try {
        await this.$confirm(
          confirmMessage,
          this.$t('common.remove_confirm.title'),
          {
            confirmButtonText: this.$t('common.interactions.buttons.ok'),
            cancelButtonText: this.$t('common.interactions.buttons.cancel'),
            type: 'warning'
          }
        )
      } catch (error) {
        // no-op
        return
      }
      this.form.processes = this.form.processes.filter((p) => p !== row.id)
    },
    normaliseKeys(item) {
      return {
        ...item,
        title_main: item.name
      }
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
}

.form-wrapper :deep(.el-form) {
  display: flex;
  justify-content: center;
}

.edit-form-wrapper {
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 80%;
  height: 100%;
  overflow: auto;
}

.form-column {
  margin: 0;
  padding: 1em;
}

.midst-body {
  margin-top: 40px;
}

.required-marker {
  position: absolute;
  right: -15px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
}

.form-header {
  font-weight: bold;
  margin-top: 20px;
}

.el-form-item {
  margin: 10px 0 20px 0;
}

@media (min-width: 1200px) {
  .form-wrapper :deep(.el-col:first-of-type) {
    padding-right: 100px;
  }
}

.el-input__icon {
  font-size: 18px;
  position: absolute;
  right: 15px;
}

.vjs-tree :deep(*) {
  font-family: monospace;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.empty-table-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.empty-table-text {
  line-height: normal;
  margin-top: 20px;
  color: var(--secondary-color);
}

.add-processes-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
}
</style>
